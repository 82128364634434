@font-face {
  font-family: "theramin";
  src: local("theramin"), url("./assets/theramin.ttf") format("truetype");
}

@font-face {
  font-family: "HACKER";
  src: local("HACKER"), url("./assets/HACKER.otf") format("opentype");
}

@font-face {
  font-family: "GT Pressura Mono";
  src: local("GT Pressura Mono"),
    url("./assets/GTPressuraMono.otf") format("opentype");
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */

html {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 830px) {
  html {
    width: 100%;
    height: 100%;
  }
}

body {
  text-align: center;
  width: 100%;
  height: 100%;

  background-color: #010614;
  overflow: hidden;
}

.notfound {
  color: white;
  text-align: center;
}

.blur {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  overflow: hidden;
  filter: blur(40px);
}

.blobs {
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
  position: relative;
}

.blob {
  /* filter: blur(20px);                */
}

/* ALL WITH GRID BACKGROUND */
.gridbg {
  background-color: rgba(1, 6, 20, 0.4);
  position: relative;
  display: inline-block;

  /* opacity: 50%; */
}

.gridbg::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(#ffffff10 0 1px, #01061400 1px 100%),
    repeating-linear-gradient(90deg, #ffffff10 0 1px, #01061400 1px 100%);
  background-size: 50px 50px;
  z-index: -1;
}

.buttonbounce {
  transition: transform 0.1s ease;
}

.buttonbounce:hover {
  transform: translateY(1px);
}

.buttonbounce:active {
  transform: translateY(4px);
}
/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */

* {
  cursor: url(https://cur.cursors-4u.net/cursors/cur-1/cur7.cur), auto !important;
  box-sizing: border-box;
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */

/* FOR MOBILE */
.landingpage {
  max-height: 100%;
  min-width: 100%;
  height: calc(100% - 20px);
  margin: 10px 0px;
}

.landingpageDESKTOP {
  max-height: 100%;

  min-width: 700px;
  max-width: 700px;
  min-height: 700px;
  max-height: 700px;
  margin: 10px 0px;
}

/* for everything w the border and glow on hover */
.element {
  border: 1px solid white;
  filter: drop-shadow(0 0 0.75rem white);
  transition: 0.2s ease-in;
}

/* for everything w the border and glow on hover */
.element:hover,
.element:focus {
  filter: drop-shadow(0 0 1.2rem rgb(255, 209, 217));
}

/* 📌 */
.title {
  background: linear-gradient(
    -45deg,
    rgba(255, 39, 194, 0) 17px,
    rgba(255, 255, 255, 1) 17px
  );
  color: rgba(1, 6, 20, 0.6);
  left: 0px;
  top: 0px;
  height: 20px;
  font-size: 19px;
  font-weight: 800;
  position: absolute;
  display: flex;
  padding: 20px 35px 15px 10px;
  align-items: center;
  font-family: "theramin", monospace;
}

/* 📌 */
.gridbg:hover > .title::before {
  content: "";
  background: linear-gradient(
    -45deg,
    transparent,
    14px,
    rgb(255, 209, 217) 14px
  );

  left: 0px;
  padding-right: 10px;
  top: 0px;
  height: 90%;
  position: absolute;
  z-index: -1;
  width: 100%;
}

.gridbg > .title::before {
  content: "";
  width: 80%;
  background: linear-gradient(
    -45deg,
    transparent,
    14px,
    rgb(255, 209, 217) 14px
  );

  left: 0px;
  padding-right: 10px;
  top: 0px;
  height: 90%;
  position: absolute;
  z-index: -1;
  transition: width 350ms;
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* NAV BAR */

.nav a {
  color: white;
  text-decoration: none;
}

.navlinktext {
  display: inherit;
}

.mishmortlogo {
  width: 60px;
  min-height: 100px;
  background-image: url("./assets/logos/mishmortlogo.svg");
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60px;
}

/* 📌 */
/* 🛸 stays bc has selector partner */
/* 🛸 only want this navbar text decoration in desktop*/
@media only screen and (min-width: 1024px) {
  .navlinktext {
    display: inline-block;
  }

  .navlinktext::after {
    content: "";
    width: 100%;
    background: white;
    height: 1px;
    margin-top: 4px;
    display: block;
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  .navlinktext:hover::after {
    transform: scaleX(1);
  }
}

.pages .navlink:not(:last-child) {
  margin-right: 10px;
}

@media only screen and (min-width: 1024px) {
  .pages:not(:last-child) {
    margin-right: 0px;
  }
}

/* 📌 */
/* 🛸 stays bc has selector partner */
/* .homelogo {
  opacity: 80%;
  transition: opacity 0.2s ease;
}

.homelogo:hover {
  opacity: 100%;
} */

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* HOMEPAGE */

.mort {
  margin-left: 80px;
}

.statafter {
  display: none;
}

.statani {
  background-image: url("./assets/logos/mishmortlogo.svg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 600px;
  animation: flicker 3s ease-in-out;
}

.homeMobile,
.notfound {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 20px;
  font-family: "GT Pressura Mono";

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.notfound {
  width: 40%;
  height: 60%;
  padding: 0px;
}

@keyframes flicker {
  0%,
  2%,
  35%,
  37%,
  39%,
  41%,
  100% {
    opacity: 1;
  }

  1%,
  3%,
  34%,
  36%,
  38%,
  40% {
    opacity: 0;
  }
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* PINS */

.innerpins {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  animation: spinny 3s ease-in-out reverse infinite;
}

.pin {
  width: 20%;
  height: 50%;
  transform-origin: bottom;
  top: 0px;
  position: absolute;
  background-image: url("./assets/pin.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.pinbody {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: white;
}

.binaryipsum {
  color: white;
  font-family: courier-std, monospace;
  font-size: 14px;
  height: 100%;
  text-align: left;
  animation: pinmove 4s linear infinite;
}

@keyframes pinmove {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* DRAW */

.placeholderwrap,
.outerdraw {
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholderwrap {
  position: absolute;
  width: 200px;
  height: 200px;
}
.canv {
  z-index: 1;
}
.ph {
  background-color: white;
  position: absolute;
}

.phx {
  width: 50px;
  height: 2px;
}

.phy {
  width: 2px;
  height: 50px;
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* KNOWLEDGE */

.innerknowledge {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.bar {
  width: 15%;
  height: 80%;
  margin: 10px;
  border: 1px solid white;
  display: flex;
  align-items: flex-end;
}

.innerbar {
  margin: 5px;
  width: 100%;
  height: 5%;
  background-color: white;
  transition: height 0.2s ease;
}

.innerknowledge:hover > .bar > .bar1 {
  height: 33%;
}

.innerknowledge:hover > .bar > .bar2 {
  height: 66%;
}

.innerknowledge:hover > .bar > .bar3 {
  height: 90%;
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* GLOBE */

.outerglobe {
  display: flex;
  justify-content: center;
  align-items: center;
}

.globe {
  width: 200px;
  height: 200px;
  background: url("./assets/globe.svg");
  border-radius: 50%;
  background-size: 450px;
  border: 1px solid white;
  animation-name: rotate;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 12s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@keyframes rotate {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 450px 0px;
  }
}
@-webkit-keyframes rotate {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 450px 0px;
  }
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* MISH */

.outermish {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innermish {
  width: 200px;
  height: 200px;
  background: url("./assets/mish.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eyewrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eye {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  margin: 20px;
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* SPINTEXT */

.outerspintext,
.innerspintext {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerspintext {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url("./assets/spark.svg");
  background-position: center;
  background-size: 50%;
}

.text {
  position: absolute;
  font-size: 16px;
  max-width: 50%;
  color: white;
  font-family: courier-std, monospace;
  /* font-family: "gost"; */
  animation: revolve 8000ms linear infinite;
}

.text span {
  position: absolute;
  top: -100px;
  transform-origin: 0 100px;
}

@keyframes revolve {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* GAME */

.outergame {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innergame {
  display: grid;
  grid-gap: 1px;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.button {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  background-color: transparent;
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* SPINNY */

.innerspinny {
  width: 100%;
  height: 100%;
  display: grid;

  padding: 20px 40px 40px 20px;

  grid-template-areas:
    ". thincircle"
    "thickcircle .";
}

.thincircle {
  width: 115%;
  height: 115%;
  background-image: url("./assets/thincircle.svg");
  background-position: center;
  background-repeat: no-repeat;
  grid-area: thincircle;
  animation: spinny 3s linear infinite;
}

.thickcircle {
  width: 115%;
  height: 115%;
  background-repeat: no-repeat;
  background-image: url("./assets/thickcircle.svg");
  background-position: center;
  grid-area: thickcircle;
  animation: spinny 3s linear infinite reverse;
}

@keyframes spinny {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* GRAPH */

.innergraph {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.out,
.mid,
.inn {
  position: absolute;
}

.out {
  width: 80%;
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.mid {
  width: 30%;

  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.inn {
  width: 15%;

  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.inn:hover,
.mid:hover,
.out:hover {
  fill: white;
}

.out:hover + .mid,
.out:hover + .mid + .inn,
.mid:hover + .inn {
  stroke: #010614;
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* SKILLS + EDUCATION */

/* 📌 */
.skills {
  grid-area: skills;
}

/* 📌 */
.releventcourses {
  grid-area: releventcourses;
}

/* 📌 */
.mcmaster {
  grid-area: mcmaster;
}

/* 📌 */
.waterloo {
  grid-area: waterloo;
}

.waterlootitle,
.mcmastertitle {
  height: 27px;
  top: 0px;
  left: 0px;
  color: rgba(1, 6, 20, 0.6);
  background-color: white;
  font-size: 17px;
  position: absolute;
  display: flex;
  padding-left: 10px;
  padding-right: 15px;
  align-items: center;
  font-family: "theramin", monospace;
}

@media only screen and (min-width: 550px) {
  .waterlootitle,
  .mcmastertitle {
    height: 30px;
    top: 0px;
    left: 0px;
    color: rgba(1, 6, 20, 0.6);
    background-color: white;
    font-size: 17px;
    position: absolute;
    display: flex;
    padding-left: 10px;
    padding-right: 15px;
    align-items: center;
    font-family: "theramin", monospace;
  }
}

.mcmastertitle,
.waterlootitle {
  width: 40%;
  height: 100%;
  text-align: left;
  font-size: 20px;
  padding-left: 10px;
}

@media only screen and (min-width: 550px) {
  .mcmastertitle,
  .waterlootitle {
    width: 33%;
    height: 100%;
    text-align: left;
    font-size: 40px;
    padding-left: 20px;
  }
}

#programinfo {
  font-size: 9px;
  padding-left: 45%;
  font-family: "GT Pressura Mono", sans-serif;
  font-weight: 300;
}

@media only screen and (min-width: 550px) {
  #programinfo {
    width: 100%;
    font-size: 18px;
    padding-left: 35%;
    font-family: "GT Pressura Mono", sans-serif;
  }
}

#programname {
  padding-left: 45%;
  width: 30%;
  font-family: "GT Pressura Mono", sans-serif;
}
@media only screen and (min-width: 550px) {
  #programname {
    width: 100%;
    font-size: 25px;
    padding-left: 35%;
    font-weight: 100 !important;
    font-family: "GT Pressura Mono", sans-serif;
  }
}

.programnameinfo {
  width: 80%;
  max-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media only screen and (min-width: 550px) {
  .programnameinfo {
    width: 80%;
    max-height: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly; */
  }
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* EXPERIENCE */

/* 📌 */
.exp {
  height: 100%;
  color: white;
  text-align: left;
  display: flex;
}

.employer {
  font-size: 12px;
  padding: 10px 0px;
  font-family: "GT Pressura Mono";
}

.duration {
  font-size: 9px;
  font-family: "GT Pressura Mono";
}

@media only screen and (min-width: 480px) {
  .employer {
    font-size: 20px;
    padding: 10px 0px;
    font-family: "GT Pressura Mono";
  }

  .duration {
    font-family: "GT Pressura Mono";
    font-size: 15px;
  }
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* PROJECTS */

.proj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.projdetails {
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: 100%;
  height: 33%;
  padding: 1px;
  font-size: 8px;
  background-color: white;
  color: #010614;
  box-shadow: 0 0 0 2px white;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: "GT Pressura Mono";
}

@media only screen and (min-width: 1024px) {
  .projdetails {
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: 50%;
    height: 50%;
    padding: 5px;
    font-size: 13px;
    background-color: white;
    color: #010614;
    box-shadow: 0 0 0 2px white;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: "GT Pressura Mono";
  }
}

.projdetails > p {
  width: 100%;
  margin: 0;
  border: 1px solid;
  border: 1px solid #010614;
  padding: 5px;
}

.projdetails ul {
  margin: 2px 0px;
  list-style-type: square;
  align-items: flex-start;
  width: 100%;
  height: 40%;
  overflow-wrap: break-word;

  padding-inline-start: 10px;
  column-count: 2;
  padding: 2px 15px;
}

#projlist li {
  font-size: 10px;
}

.projlink {
  width: 100%;
  height: 25px;
  margin-top: 1px;
  background-color: transparent;
  border: 1px solid #010614;
  color: #010614;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projlink:hover,
.projlink:focus {
  background-color: #010614;
  color: white;
}

.proj0:hover > .thumb0,
.proj1:hover > .thumb1,
.proj2:hover > .thumb2,
.proj3:hover > .thumb3 {
  filter: grayscale(0%);
  transition: filter 0.3s;
  /* width: 1%; */
}

.thumb {
  filter: grayscale(100%);
}

.thumb0 {
  background-image: url("./assets/pmthumb.png");
}

.thumb1 {
  background-image: url("./assets/wosthumb.svg");
}

.thumb2 {
  background-image: url("./assets/telescopethumb.png");
}

/* .thumb2 {
  background-image: url("./assets/fcthumb.svg");
} */

.thumb3 {
  background-image: url("./assets/misheggthumb.png");
}

/* ｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨｡･:*:･ﾟ★💕｡･:*:･ﾟ☆✨ */
/* ABOUT */

/* FOR DESKTOP */
/* 📌 */
@media only screen and (min-width: 480px) {
  .bio {
    padding: 40px;
  }
}

.resumetitle {
  border: 1px solid white;
  color: white;
  background-color: rgba(1, 6, 20, 0.4);
  opacity: 0.8;
  display: flex;
  font-family: "theramin", monospace;

  justify-content: center;
  align-items: center;
}

.link:hover {
  opacity: 1;
  cursor: pointer;
}

.link:active {
  background: darken(#010614, 20%);
}

.selfie {
  border: 1px solid white;
  background-image: url("./assets/selfie.jpg");
  background-position: center;
  background-size: cover;
  grid-area: selfie;
}

.selfieMobile {
  border: 1px solid white;
  background-image: url("./assets/mish.svg");
  background-position: center;
  grid-area: selfie;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selfieEyes {
  width: 50px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selfieEye {
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
}

.rSelfieEye {
  margin-left: 5px;
}

.lSelfieEye {
  margin-right: 5px;
}

.tlcorner {
  border-left: 1px solid white;
  border-top: 1px solid white;
  position: absolute;
  top: 20px;
  left: 20px;
  /* border-top-left-radius: 3px; */
}

.trcorner {
  border-right: 1px solid white;
  border-top: 1px solid white;
  position: absolute;
  top: 20px;
  right: 20px;
  /* border-top-right-radius: 3px; */
}

.blcorner {
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  position: absolute;
  bottom: 20px;
  left: 20px;
  /* border-bottom-left-radius: 3px; */
}

.brcorner {
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  position: absolute;
  bottom: 20px;
  right: 20px;
  /* border-bottom-right-radius: 3px; */
}

.bio,
.link,
.selfie {
  /* border: 1px solid white; */
  /* border-radius: 3px; */
  /* filter: drop-shadow(0 0 0.75rem white); */
  /* transition: 0.2s ease-in; */
}

.bio:hover,
.link:hover,
.selfie:hover {
  /* filter: drop-shadow(0 0 1.2rem white); */
}
